<template>
  <div class="max-w1200 mrauto">
    <div class="ptb30 borb-E6E6E6">
      <div class="flex">
        <div class="per-w50 mr26">
          <!-- <div>
            <div style="width: 800px; height: 800px; margin: 20px 0 0 700px">
              <div style="width: 400px; height: 400px">
                <img :src="mainImgUrl" style="width: 100%; height: 100%" />
              </div>
              <div>
                <i
                  style="
                    font-size: 30px;
                    display: inline-block;
                    position: relative;
                    top: 40px;
                    cursor: pointer;
                  "
                  class="el-icon-arrow-left"
                  @click="imgLeft()"
                ></i>
                <ul class="Img_ul">
                  <li
                    v-for="(item, index) in imgUrlList"
                    :key="index"
                    class="Img_li"
                    :style="imgStyle"
                    @click="changeImg(item, index)"
                  >
                    <img
                      :class="index === imgActiveIndex ? 'img_activeBorder' : ''"
                      :src="item"
                      style="width: 50px; height: 50px"
                    />
                  </li>
                </ul>
                <i
                  style="
                    font-size: 30px;
                    display: inline-block;
                    position: relative;
                    left: 370px;
                    top: -50px;
                    cursor: pointer;
                  "
                  class="el-icon-arrow-right"
                  @click="imgRight()"
                ></i>
              </div>
            </div>
          </div> -->
          <el-carousel
            indicator-position="outside"
            height="25rem"
            ref="carousel"
            @change="changeItem"
            trigger="click"
          >
            <el-carousel-item v-for="item in bannerImg" :key="item">
              <img :src="item" alt="" class="h400 per-w100" />
            </el-carousel-item>
          </el-carousel>
          <div>
            <div class="zs-adv">
              <div id="adv-pad-scroll">
                <!-- <i
                  style="
                    font-size: 30px;
                    display: inline-block;
                    position: relative;
                    top: 40px;
                    cursor: pointer;
                  "
                  class="el-icon-arrow-left"
                  @click="imgLeft()"
                ></i> -->
                <div class="adv-pad" ref="all">
                  <div
                    class="adv-pad-item"
                    v-for="(item, index) in bannerImg"
                    :key="index"
                    ref="demoItem"
                    :class="active == index ? 'active' : ''"
                    @click="clickBtn(index)"
                    :style="imgStyle"
                  >
                    <img alt="" :src="item" />
                  </div>
                </div>
                <!-- <i
                  style="
                    font-size: 30px;
                    display: inline-block;
                    position: relative;
                    left: 370px;
                    top: -50px;
                    cursor: pointer;
                  "
                  class="el-icon-arrow-right"
                  @click="imgRight()"
                ></i> -->
              </div>
            </div>
          </div>
          <!-- <div class="flex a-center distrbute_btns">
            <div
              class="distrbute_btns_li"
              v-for="(item, index) in bannerImg"
              :key="index"
              @click="clickBtn(index)"
              :class="active == index ? 'active' : ''"
            >
              <img :src="item" />
            </div>
          </div> -->
        </div>
        <div class="per-w50">
          <div class="fwb fs-26 col-333333">
            {{ info.title }}
          </div>
          <div class="fs-14 flex a-center j-sb mt14">
            <div>
              <!-- <span class="mr20 ln-bl col-333333"
                >发布时间：{{ info.create_time_text }}</span
              > -->
              <span class="ln-bl col-333333 mr10">信息编号：{{ info.code }}</span>
            </div>
            <div class="flex a-center">
              <div
                class="col-CC0000 flex a-center cup mr5 fs-14"
                v-if="info.status == 4 && info.status != 3 && info.status != 10"
              >
                <div class="w18h18 shrink mr2">
                  <img src="../../assets/img/jubao.png" alt="" class="w18h18" />
                </div>
                已举报
              </div>
              <div
                class="col-333333 flex a-center cup mr5 fs-14"
                @click="dialogShow(4)"
                v-if="info.status != 4 && info.status != 3 && info.status != 10"
              >
                <div class="w18h14 shrink mr2">
                  <img
                    src="https://pt.baipubang.com/app_icon/index/jubao.png"
                    alt=""
                    class="w18h14"
                  />
                </div>
                举报已转
              </div>
              <div
                class="col-CC0000 flex a-center cup mr5 fs-14"
                @click="dialogShow(1)"
                v-if="info.is_coll == 1 && info.status != 10"
              >
                <div class="w18h18 shrink mr2">
                  <img
                    src="https://pt.baipubang.com/app_icon/index/yidainzan.png"
                    alt=""
                    class="w18h18"
                  />
                </div>
                已收藏
              </div>
              <div
                class="col-333333 flex a-center cup mr5 fs-14"
                @click="collectClick"
                v-if="info.is_coll != 1 && info.status != 10"
              >
                <div class="w18h14 shrink mr2">
                  <img src="../../assets/img/shoucang.png" alt="" class="w18h14" />
                </div>
                感兴趣
              </div>
              <div
                class="col-CC0000 flex a-center cup mr5 fs-14"
                @click="dialogShow(5)"
                v-if="info.status != 10 && info.status != 3"
              >
                <div class="w22h22 shrink mr2">
                  <img src="../../assets/img/shizhong.png" alt="" class="w22h22" />
                </div>
                约看店
              </div>
              <div
                class="col-CC0000 flex a-center cup mr5 fs-14"
                @click="dialogShow(3)"
                v-if="info.status != 3 && info.status != 10 && is_take_over==0"
              >
                <div class="w22h22 shrink mr2">
                  <img src="../../assets/img/gou.png" alt="" class="w22h22" />
                </div>
                确定接
              </div>
              <div
                class="col-CC0000 flex a-center cup fs-14"
                @click="dialogShow(2)"
                v-if="info.status != 3 && info.status != 10"
              >
                <div class="w20h20 shrink mr2">
                  <img src="../../assets/img/fengxiang.png" alt="" class="w20h20" />
                </div>
                乐分享
              </div>
            </div>
          </div>
          <div class="flex a-center ptb10 borb-E6E6E6">
            <div class="text-center per-w33">
              <div class="fs-30 fwb col-FF0000">
                {{ info.area }}<span v-if="info.area_unit == 1">㎡</span>
                <span v-if="info.area_unit == 2">亩</span>
              </div>
              <div class="fs-14 col-333333 mt10">面积</div>
            </div>
            <div class="text-center per-w33 borl-E6E6E6" v-if="s_type == 1">
              <div class="fs-30 fwb col-FF0000">
                <span v-if="!info.rent_price || info.rent_price == 0">面谈</span>
                <span v-else
                  >{{ info.rent_price }}{{ info.money_unit }}/
                  <span v-if="info.rent_time == 1">年</span>
                  <span v-if="info.rent_time == 2">月</span>
                  <span v-if="info.rent_time == 3">平方</span></span
                >
              </div>
              <div class="fs-14 col-333333 mt10">租金</div>
            </div>
            <div
              class="text-center per-w33 borl-E6E6E6"
              v-if="s_type == 2 && info.type == 2"
            >
              <div class="fs-30 fwb col-FF0000">
                <span v-if="info.sell_type == 0">面谈</span>
                <span v-if="info.sell_type == 1"
                  >{{ info.sell_money }}{{ info.money_unit }}/总价</span
                >
                <span v-if="info.sell_type == 2"
                  >{{ info.sell_money }}{{ info.money_unit }}/平方</span
                >
              </div>
              <div class="fs-14 col-333333 mt10">费用</div>
            </div>
            <div
              class="text-center per-w33 borl-E6E6E6"
              v-if="info.type == 1 && s_type == 1"
            >
              <div class="fs-30 fwb col-FF0000">
                <span v-if="!info.sell_price || info.sell_price == 0">面谈</span>
                <span v-else-if="info.sell_price == '-1'">无转让费</span>
                <span v-else>{{ info.sell_price }}万元</span>
              </div>
              <div class="fs-14 col-333333 mt20">转让费</div>
            </div>
          </div>
          <div class="por pt10">
            <div class="fs-14" v-if="s_type == 1">
              <div class="mb10">
                <span v-if="info.type == 1" class="shrink">目前经营：</span>
                <span v-if="info.type == 2" class="shrink">曾经经营：</span>
                <span v-for="(item, index) in info.classify_two" :key="index"
                  >{{ item }},</span
                >
              </div>
              <div class="mb10">
                <span class="shrink">营业状况：</span>
                <span v-for="(item, index) in info.open_status" :key="index"
                  >{{ item.name }},</span
                >
              </div>
              <div v-if="info.type == 1" class="mb10">
                <span class="shrink">剩余租期：</span>
                <span>{{ info.contract_time_text }}</span>
              </div>
              <div v-if="info.type == 2" class="mb10">
                <span class="shrink">最短租期：</span>
                <span v-for="(item, index) in info.rental" :key="index"
                  >{{ item.name }},</span
                >
              </div>
              <div v-if="info.type == 2" class="mb10">
                <span class="shrink">免租天数：</span>
                <span v-if="info.free_day && info.free_day !== 0"
                  >{{ info.free_day }}天</span
                >
                <span v-else>可谈</span>
              </div>
              <div v-if="info.type == 1" class="mb10">
                <span class="shrink">可否空转：</span>
                <span v-if="info.is_null_poss == 0">不可以</span>
                <span v-if="info.is_null_poss == 1">可以</span>
              </div>
              <div class="mb10">
                <span class="shrink">适合行业：</span>
                <span v-for="(item, index) in info.fit_industry" :key="index"
                  >{{ item }},</span
                >
              </div>
              <div class="mb10">
                <span class="shrink">所在楼层：</span>
                <span>{{ info.floor_name }}</span>
              </div>

              <div class="mb10">
                <span class="shrink">商圈/街道：</span>
                <span>{{ info.street }}</span>
              </div>
              <div class="mb10 flex a-center j-sb">
                <div>
                  <span class="shrink">详细地址：</span>
                  <span
                    >{{ info.province }}{{ info.city }}{{ info.city_area
                    }}{{ info.address }}</span
                  >
                </div>
                <div class="flex a-center">
                  <div class="col-CC0000 ml10 fs-10 cup shrink" @click="daohang">
                    <div class="wh20 mb5">
                      <img
                        src="https://pt.baipubang.com/app_icon/index/daohang.png"
                        alt=""
                        class="wh20"
                      />
                    </div>
                    导航
                  </div>
                  <div class="ml10 col-CC0000 fs-10 cup shrink" @click="getpanoid">
                    <div class="wh20 mb5">
                      <img
                        src="https://pt.baipubang.com/app_icon/index/shijing.png"
                        alt=""
                        class="wh20"
                      />
                    </div>
                    实景
                  </div>
                  <div class="col-CC0000 ml10 fs-10 cup shrink" @click="daohang2">
                    <div class="wh20 mb5">
                      <img
                        src="../../assets/img/icon_zhoubianpeitao.png"
                        alt=""
                        class="wh20"
                      />
                    </div>
                    商圈分析
                  </div>
                </div>
                <!-- <span class="col-CC0000 cup"> 导航到店 > </span> -->
              </div>
            </div>
            <div class="fs-14" v-if="s_type == 2">
              <div class="mb10">
                <span class="shrink">适合行业：</span>
                <span v-for="(item, index) in info.fit_industry" :key="index"
                  >{{ item }},</span
                >
              </div>
              <div class="mb10">
                <span class="shrink">所在楼层：</span>
                <span>{{ info.floor_name }}</span>
              </div>
              <div class="mb10">
                <span class="shrink">详细地址：</span>
                <span
                  >{{ info.province }}{{ info.city }}{{ info.city_area
                  }}{{ info.address }}</span
                >
                <!-- <span class="col-CC0000 cup"> 导航到店 > </span> -->
              </div>
            </div>
            <div
              class="poa-rt"
              v-if="
                (info.status != 3 && info.ad_loc == 1) ||
                (info.status != 3 && info.ad_loc == 2)
              "
            >
              <img
                src="https://pt.baipubang.com/uploads/img/xiangqing/zhang.png"
                alt=""
                class="w146h146"
              />
            </div>
            <div
              class="poa-rt"
              v-if="
                (info.status == 3 && s_type == 1) || (s_type == 2 && info.status == 3)
              "
            >
              <img
                src="https://pt.baipubang.com/uploads/img/xiangqing/yichengjiao.png"
                alt=""
                class="w194h146"
              />
            </div>
          </div>
          <div class="flex">
            <div class="per-w60 mr7">
              <div class="ptb14lr35 br10 bg-CC0000 col-ffffff flex a-center h70 box-b" v-if="info.status != 3">
                <div class="per-w30 borr-ffffff text-center fs-20">
                  {{ info.contacts || info.name }}
                </div>
                <div class="per-w70 pl30" v-if="info.status != 3">
                  <div class="mr20">
                    {{ info.phone }}
                  </div>
                  <!-- <div class="flex j-sb">
                    <div></div>
                    <div class="ptb4lr11 br100 fs-14 bor-ffffff cup">在线咨询</div>
                  </div> -->
                </div>
              </div>
              <div class="ptb14lr35 br10 bg-CC0000 col-ffffff flex a-center h70 box-b" v-else>
                <div class="text-center fs-20 per-w100">
                  {{ info.contacts || info.name }}
                </div>
              </div>
            </div>
            <div class="per-w50">
              <div class="ptb14lr18 br10 bg-EEEEEE fs-14 col-333333 h70 box-b">
                <div>
                  <span>客服电话：</span>
                  <span class="fwb col-CC0000">{{ info.salesman_phone }}</span>
                </div>
                <div class="mt5">想了解更多信息，请拨打电话！</div>
              </div>
            </div>
          </div>
          <!-- <div class="flex a-center j-sb mt14">
            <div></div>
            <div v-if="info.status != 3 && info.status != 10" class="ml15">
              <el-button type="primary" @click="dialogShow(3)">确定接</el-button>
              <el-button type="danger" @click="dialogShow(2)">乐分享</el-button>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="flex a-end">
        <div class="flex per-w50">
          <div class="per-w60 mr7">
            <div class="ptb14lr35 br10 bg-CC0000 col-ffffff flex a-center h70 box-b">
              <div class="per-w30 borr-ffffff text-center fs-20">
                {{ info.contacts || info.name }}
              </div>
              <div class="per-w70 pl30">
                <div class="mr20">
                  {{ info.phone }}
                </div>
               注释
                <div class="flex j-sb">
                  <div></div>
                  <div class="ptb4lr11 br100 fs-14 bor-ffffff cup">在线咨询</div>
                </div>
              注释
              </div>
            </div>
          </div>
          <div class="per-w40">
            <div class="ptb14lr18 br10 bg-EEEEEE fs-14 col-333333 h70 box-b">
              <div>
                <span>客服电话：</span>
                <span class="fwb col-CC0000">{{ info.salesman_phone }}</span>
              </div>
              <div class="mt5">想了解更多信息，请拨打电话！</div>
            </div>
          </div>
        </div>
        <div v-if="info.status != 3 && info.status != 10" class="ml15">
          <el-button type="primary" @click="dialogVisible3 = true">确定接</el-button>
          <el-button type="danger" @click="dialogVisible2 = true">乐分享</el-button>
        </div>
      </div> -->
    </div>
    <!-- 成功案列详情 -->
    <div
      class="borb-E6E6E6 mt50 flex a-center j-sb"
      v-if="(info.status == 3 && s_type == 1) || (s_type == 2 && info.status == 3)"
    >
      <div class="flex a-center">
        <div class="h60 w140 flex a-center fs-24 borb-d43030 mr26">详细信息</div>
        <div
          class="h60 w140 cup flex a-center j-center fs-15 mr5"
          :class="activeStep == index ? 'bg-d43030 col-ffffff' : 'bg-EEEEEE col-333333'"
          v-for="(item, index) in typeList2"
          :key="index"
          @click="jump(index)"
        >
          <!-- @click="WinportClick(item.id)" -->
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="borb-E6E6E6 mt50 flex a-center j-sb" v-else>
      <div class="flex a-center">
        <div class="h60 w140 flex a-center fs-24 borb-d43030 mr26">详细信息</div>
        <div
          class="h60 w140 cup flex a-center j-center fs-15 mr5"
          :class="activeStep == index ? 'bg-d43030 col-ffffff' : 'bg-EEEEEE col-333333'"
          v-for="(item, index) in typeList"
          :key="index"
          @click="jump(index)"
        >
          <!-- @click="WinportClick(item.id)" -->
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="ptb20 flex">
      <!-- 成功案列详情 -->
      <div
        class="ptb20 flex1 mr20 scroll-content"
        @scroll="onScroll"
        v-if="(info.status == 3 && s_type == 1) || (s_type == 2 && info.status == 3)"
      >
        <div class="scroll-item">
          <div class="col-CC0000 fs-20 mb30 ptb20">店铺介绍：</div>
          <div v-html="info.introduce"></div>
        </div>
        <div class="scroll-item">
          <div class="col-CC0000 fs-20 mb30 ptb20">商家评论：</div>
          <div>
            <div v-for="(item, index) in commentInfo" :key="index">
              <div v-html="item.comment_text" class="mb10"></div>
              <div v-if="item.images && item.images.length" class="flex f-w">
                <div v-for="(items, indexs) in item.images" :key="indexs" class="mr10">
                  <el-image
                    style="width: 12.5rem; height: 12.5rem"
                    :src="items"
                    :preview-src-list="item.images"
                  >
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="scroll-item">
          <div class="col-CC0000 fs-20 mb30 ptb20">服务记录：</div>
          <div>
            <div v-for="(item, index) in info.service_log" :key="index" class="mb20">
              <el-image
                style="width: 100%; height: 100%"
                :src="item"
                :preview-src-list="info.service_log"
              >
              </el-image>
            </div>
          </div>
        </div>
      </div>
      <!-- 非成功案列 -->
      <div class="ptb20 flex1 mr20 scroll-content" @scroll="onScroll" v-else>
        <div class="scroll-item">
          <div class="col-CC0000 fs-20 mb30 ptb20">店铺介绍：</div>
          <div v-html="info.introduce"></div>
        </div>
        <div class="scroll-item">
          <div class="col-CC0000 fs-20 mb30 ptb20">配套设施：</div>
          <div>
            <ul class="flex a-center">
              <li
                class="w106h86 bor-c1c1c1 flex j-center a-center"
                v-for="(item, index) in info.matching"
                :key="index"
              >
                <div>
                  <div class="w42h39 mrauto mb10">
                    <img :src="item.img" alt="" class="w42h39" />
                  </div>
                  <div class="text-center fs-16 col-333333">
                    {{ item.name }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="scroll-item">
          <div class="col-CC0000 fs-20 mb30 ptb20">视频看店：</div>
          <div v-if="info.video">
            <video width="30rem" height="16.875rem" controls :src="info.video"></video>
          </div>
        </div>
        <div class="scroll-item">
          <div class="col-CC0000 fs-20 mb30 ptb20">照片详情：</div>
          <div class="flex f-w">
            <div
              v-for="(item, index) in bannerImg"
              :key="index"
              class="per-w50 box-b pd4 h380 mb15"
            >
              <div class="h380 per-w100">
                <img :src="item" alt="" class="h380 per-w100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 已成交更多案列相似推荐 -->
      <div class="per-w22 bor-DADADA" v-if="(info.status == 3 && s_type == 1) || (s_type == 2 && info.status == 3)">
        <div class="h40 l-h40 bg-CC0000 fs-20 col-ffffff text-center" @click="goSuccessList">更多案例</div>
        <ul class="plr5">
          <li
            class="ptb15 borb-DADADA flex cup"
            v-for="(item, index) in store_list"
            :key="index"
            @click="detailsCut(item)"
          >
            <div class="flex1 h72 mr5">
              <img :src="item.img" alt="" class="per-w100 h72" />
            </div>
            <div class="fs-12 col-333333 per-w50">
              <div class="fs-14 ft-1">
                {{ item.title }}
              </div>
              <div>
                {{ item.class_name }}
              </div>
              <div>
                面积：<span class="col-FF0000">{{ item.area }}</span
                ><span class="col-FF0000" v-if="item.area_unit == 1">m²</span
                ><span class="col-FF0000" v-if="item.area_unit == 2">亩</span>
              </div>
              <div v-if="item.s_type == 1">
                租金：<span
                  class="col-FF0000"
                  v-if="item.rent_price == 0 || !item.rent_price"
                  >面议</span
                ><span class="col-FF0000" v-else
                  >{{ item.rent_price }}{{ item.money_unit }}/<span
                    v-if="item.rent_time == 1"
                    >年</span
                  ><span v-if="item.rent_time == 2">月</span
                  ><span v-if="item.rent_time == 3">平方</span></span
                >
              </div>
              <div v-if="item.s_type == 2 && item.type == 2">
                费用：<span class="col-FF0000" v-if="item.sell_type == 0">面议</span>
                <span class="col-FF0000" v-if="item.sell_type == 1"
                  >{{ item.sell_price }}{{ item.money_unit }}/总价</span
                >
                <span class="col-FF0000" v-if="item.sell_type == 2"
                  >{{ item.sell_price }}{{ item.money_unit }}/平方</span
                >
              </div>
              <div v-if="item.type == 1 && item.s_type == 1">
                转让费：<span
                  class="col-FF0000"
                  v-if="!item.sell_price || item.sell_price == 0"
                  >面议</span
                >
                <span v-else-if="item.sell_price == '-1'" class="col-FF0000">无</span>
                <span class="col-FF0000" v-else>{{ item.sell_price }}万</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 类似店铺 -->
      <div class="per-w22 bor-DADADA" v-else>
        <div class="h40 l-h40 bg-CC0000 fs-20 col-ffffff text-center">类似店铺</div>
        <ul class="plr5">
          <li
            class="ptb15 borb-DADADA flex cup"
            v-for="(item, index) in recommendList"
            :key="index"
            @click="detailsCut(item)"
          >
            <div class="flex1 h72 mr5">
              <img :src="item.img" alt="" class="per-w100 h72" />
            </div>
            <div class="fs-12 col-333333 per-w50">
              <div class="fs-14 ft-1">
                {{ item.title }}
              </div>
              <div>
                {{ item.class_name }}
              </div>
              <div>
                面积：<span class="col-FF0000">{{ item.area }}</span
                ><span class="col-FF0000" v-if="item.area_unit == 1">m²</span
                ><span class="col-FF0000" v-if="item.area_unit == 2">亩</span>
              </div>
              <div v-if="item.s_type == 1">
                租金：<span
                  class="col-FF0000"
                  v-if="item.rent_price == 0 || !item.rent_price"
                  >面议</span
                ><span class="col-FF0000" v-else
                  >{{ item.rent_price }}{{ item.money_unit }}/<span
                    v-if="item.rent_time == 1"
                    >年</span
                  ><span v-if="item.rent_time == 2">月</span
                  ><span v-if="item.rent_time == 3">平方</span></span
                >
              </div>
              <div v-if="item.s_type == 2 && item.type == 2">
                费用：<span class="col-FF0000" v-if="item.sell_type == 0">面议</span>
                <span class="col-FF0000" v-if="item.sell_type == 1"
                  >{{ item.sell_price }}{{ item.money_unit }}/总价</span
                >
                <span class="col-FF0000" v-if="item.sell_type == 2"
                  >{{ item.sell_price }}{{ item.money_unit }}/平方</span
                >
              </div>
              <div v-if="item.type == 1 && item.s_type == 1">
                转让费：<span
                  class="col-FF0000"
                  v-if="!item.sell_price || item.sell_price == 0"
                  >面议</span
                >
                <span v-else-if="item.sell_price == '-1'" class="col-FF0000">无</span>
                <span class="col-FF0000" v-else>{{ item.sell_price }}万</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <el-dialog title="温 馨 提 示" :visible.sync="dialogVisible" width="30%">
        <span>是否要取消收藏</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="delecollectClick">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="温 馨 提 示" :visible.sync="dialogVisible4" width="30%">
        <span>是否要举报此店铺</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible4 = false">取 消</el-button>
          <el-button type="primary" @click="jubao">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="乐 分 享" :visible.sync="dialogVisible2" width="30%">
        <span>{{ rich_val.text_e }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="copyFn(info.share_url)">复 制</el-button>
        </span>
      </el-dialog>
      <el-dialog title="确 定 接" :visible.sync="dialogVisible3" width="30%">
        <span>{{ rich_val.text_c }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="Sure_meet()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="街 景" :visible.sync="jiejinshow" width="50%">
        <div id="pano_holder"></div>
      </el-dialog>
      <el-dialog title="约 看 店" :visible.sync="phoneflag" width="30%">
        <div class="block">
          <span class="demonstration">选择预约看店时间：</span>
          <el-date-picker
            v-model="apptime"
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            placeholder="选择预约看店时间"
          >
          </el-date-picker>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="phoneflag = false">取 消</el-button>
          <el-button type="primary" @click="apptimeFun">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  lease_val,
  ivn_sale_val,
  similar_shop,
  add_collect,
  del_collect,
  take_over,
  report_shop,
  agreement_look,
  merchant_comment,
} from "../../utils/api.js";
export default {
  data() {
    return {
      apptime: "",
      phoneflag: false,
      jiejinshow: false,
      activeStep: 0,
      rich_val: "",
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      typeList: [
        { id: 1, name: "店铺介绍" },
        { id: 2, name: "配套设施" },
        { id: 3, name: "视频看店" },
        { id: 4, name: "照片详情" },
      ],
      typeList2: [
        { id: 1, name: "店铺介绍" },
        { id: 2, name: "商家评论" },
        { id: 3, name: "服务记录" },
      ],
      type: 1,
      bannerImg: "",
      s_type: "",
      id: "",
      lng: "",
      lat: "",
      province: "",
      city: "",
      info: "",
      recommendList: "",
      pid: "",
      uid: "",
      panoid: "",
      active: 0,
      translateNum: 0,
      timer: null,
      imgDistance: 0, // 移动的距离
      allDistance: 0, // 总移动距离
      commentInfo: "",
      store_list:"",
      is_take_over:0,
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        this.s_type = this.$route.query.s_type;
        this.id = this.$route.query.id;
        this.pid = this.$route.query.pid;
        console.log(this.id);
        this.getdata();
        this.merchant_commentFun();
      },
    },
  },
  computed: {
    imgStyle() {
      return {
        transform: `translate3d(${this.imgDistance}px, 0, 0)`, // 计算移动的距离(x,y,z)
      };
    },
  },
  mounted() {
    let uid = window.localStorage.getItem("uid");
    if (uid) {
      this.uid = uid;
    }
    //  else {
    //   this.$message.error("还未登录，请登录后再使用");
    //   setTimeout(() => {
    //     this.$router.push({
    //       path: "/login",
    //       query: { navid: 10 },
    //     });
    //     // let routeData = this.$router.resolve({
    //     //   path: "/login",
    //     //   query: { navid: 10 },
    //     // });
    //     // window.open(routeData.href, "_blank");
    //   }, 1500);
    // }
    this.s_type = this.$route.query.s_type;
    this.id = this.$route.query.id;
    this.pid = this.$route.query.pid;
    this.lng = window.localStorage.getItem("lng");
    this.lat = window.localStorage.getItem("lat");
    this.province = window.localStorage.getItem("province");
    this.city = window.localStorage.getItem("city");
    this.getdata();
    this.merchant_commentFun();
    // this.getrecommend();
  },
  methods: {
    // 去已成交案例列表
    goSuccessList(){
      this.$router.push({
        path: "/successList",
      });
    },
    dialogShow(i) {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return;
      }

      switch (i) {
        case 1:
          this.dialogVisible = true;
          break;
        case 2:
          this.dialogVisible2 = true;
          break;
        case 3:
          this.dialogVisible3 = true;
          break;
        case 4:
          this.dialogVisible4 = true;
          break;
        case 5:
          this.phoneflag = true;
          break;
        default:
          break;
      }
    },
    // changeImg(item, index) {
    //   this.mainImgUrl = item;
    //   this.imgActiveIndex = index;
    // },
    imgLeft() {
      if (this.active > 0) {
        this.active--; // 索引值-1
        this.bannerImg.forEach((item, index) => {
          // 循环小图,通过判断索引值赋值给大图
          if (this.active === index) {
            // this.active = item;
            this.$refs.carousel.setActiveItem(index);
          }
        });
      }
      if (this.active >= 4) {
        var index = 0;
        const temp = window.setInterval(() => {
          // 利用定时器实现图片左右移动的动画效果
          if (index < 64) {
            // 移动次数(33次)
            this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
            index++;
            return;
          } else {
            window.clearInterval(temp); // 移动完清除定时器
          }
        }, 10);
      }
    },
    imgRight() {
      if (this.active < this.bannerImg.length - 1) {
        this.active++;
        this.bannerImg.forEach((item, index) => {
          if (this.active === index) {
            // this.mainImgUrl = item;
            this.$refs.carousel.setActiveItem(index);
          }
        });
        if (this.active >= 5) {
          // this.allDistance = -80 * (this.active - 4);
          this.allDistance = -128 * (this.active - 4);
          var index = 0;
          const temp = window.setInterval(() => {
            if (index < 64) {
              this.imgDistance -= 2; // 每次向右移动的距离
              index++;
              return;
            } else {
              window.clearInterval(temp);
            }
          }, 10);
        }
      } else if (this.active === this.bannerImg.length - 1) {
        // 到达最后一张图片，再点击跳转回第一张
        this.active = 0;
        this.$refs.carousel.setActiveItem(0);
        var indexs = 0;
        const temp = window.setInterval(() => {
          // 利用定时器实现图片左右移动的动画效果
          if (indexs < Math.abs(this.allDistance / 2)) {
            // 取绝对值再除
            this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
            indexs++;
            return;
          } else {
            window.clearInterval(temp); // 移动完清除定时器
          }
        }, 1);
      }
    },
    clickBtn(e) {
      this.$refs.carousel.setActiveItem(e); // 将tab的下标指定为走马灯的下标
      this.active = e; // tab切换的下标
    },
    changeItem(e) {
      this.active = e; // 将走马灯的 下标 指定为 tab 的下标
      if (this.active < this.bannerImg.length - 1) {
        if (this.active >= 4) {
          // this.allDistance = -80 * (this.active - 4);
          this.allDistance = -128 * (this.active - 4);
          var index = 0;
          const temp = window.setInterval(() => {
            if (index < 64) {
              this.imgDistance -= 2; // 每次向右移动的距离
              index++;
              return;
            } else {
              window.clearInterval(temp);
            }
          }, 10);
        }
      } else if (this.active === this.bannerImg.length - 1) {
        // 到达最后一张图片，再点击跳转回第一张
        setTimeout(() => {
          this.active = 0;
          this.$refs.carousel.setActiveItem(0);
          //  this.active = 0;
          console.log(this.allDistance);
          // this.$refs.carousel.setActiveItem(0);
          // var indexs = 0;
          const temp = window.setInterval(() => {
            // 利用定时器实现图片左右移动的动画效果
            if (this.imgDistance <= 4) {
              // 取绝对值再除
              console.log(this.imgDistance);
              this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
              // indexs++;
              return;
            } else {
              window.clearInterval(temp); // 移动完清除定时器
            }
          }, 1);
        }, 2000);
      }
    },
    // 时间格式转换yyyy-mm-dd HH:mm
    dateFormat: function (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      // var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接 可改成自己所要的时间格式
      return year + "-" + month + "-" + day + " " + hours + ":" + minutes;
    },
    // 预约看店
    apptimeFun() {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return;
      }

      let that = this;
      let time = that.dateFormat(that.apptime);
      console.log(time);
      agreement_look({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
        look_time: time,
      })
        .then((res) => {
          console.log(res);
          that.$alert(res.msg, {
            callback: () => {
              that.phoneflag = false;
              that.getdata();
            },
          });
        })
        .catch((err) => {
          console.log(err);
          that.$alert(err.msg, {
            callback: () => {
              that.phoneflag = false;
              that.getdata();
            },
          });
        });
    },
    daohang2() {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return;
      }

      // let form = window.localStorage.getItem("address");
      let to =
        this.info.province + this.info.city + this.info.city_area + this.info.address;
      // https://apis.map.qq.com/uri/v1/routeplan?type=drive&from=中关村&to=望京&policy=0&referer=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77
      // window.open(
      //   "https://apis.map.qq.com/uri/v1/geocoder?coord=23.82358,118.3139&referer=XXX"
      // );
      window.open(
        "https://apis.map.qq.com/uri/v1/geocoder?coord=" +
          this.info.lat +
          "," +
          this.info.lng +
          "&referer=" +
          to
      );
    },
    daohang() {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return;
      }

      let form = window.localStorage.getItem("address");
      let to =
        this.info.province + this.info.city + this.info.city_area + this.info.address;
      // https://apis.map.qq.com/uri/v1/routeplan?type=drive&from=中关村&to=望京&policy=0&referer=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77
      window.open(
        "https://apis.map.qq.com/uri/v1/routeplan?type=drive&from=" +
          form +
          "&to=" +
          to +
          "&policy=0&referer=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77",
        "_blank"
      );
    },
    getpanoid() {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return;
      }

      this.jiejinshow = true;

      // var panoLatLng = new window.qq.maps.LatLng(Number(this.lat), Number(this.lng));
      var panoLatLng = new window.qq.maps.LatLng(39.882326, 116.326088);
      // 创建街景
      var pano = new window.qq.maps.Panorama(document.getElementById("pano_holder"));
      var pano_service = new window.qq.maps.PanoramaService();
      pano_service.getPano(panoLatLng, 200, function (result) {
        pano.setPano(result.svid);

        var x1 = result.latlng.lng;
        var y1 = result.latlng.lat;
        // var x2 = Number(this.lng);
        // var y2 = Number(this.lat);
        var x2 = 116.326088;
        var y2 = 39.882326;
        var alpha = Math.acos(
          (y2 - y1) / Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
        );
        if (x2 - x1 < 0) {
          alpha = Math.PI * 2 - alpha;
        }
        //修改场景的俯仰角
        pano.setPov({ heading: (alpha / Math.PI) * 180, pitch: 0 });
      });
    },
    onScroll(e) {
      let scrollItems = document.querySelectorAll(".scroll-item");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
          e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (judge) {
          this.activeStep = i;
          break;
        }
      }
    },
    // 点击切换锚点

    jump(index) {
      let target = document.querySelector(".scroll-content");
      let scrollItems = document.querySelectorAll(".scroll-item");
      // 判断滚动条是否滚动到底部

      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.activeStep = index;
      }

      let total = scrollItems[index].offsetTop - scrollItems[0].offsetTop; // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)

      let distance = document.querySelector(".scroll-content").scrollTop; // 滚动条距离滚动区域顶部的距离

      // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)

      // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次

      // 计算每一小段的距离

      let step = total / 50;

      if (total > distance) {
        smoothDown(document.querySelector(".scroll-content"));
      } else {
        let newTotal = distance - total;

        step = newTotal / 50;

        smoothUp(document.querySelector(".scroll-content"));
      }

      // 参数element为滚动区域

      function smoothDown(element) {
        if (distance < total) {
          distance += step;

          element.scrollTop = distance;

          setTimeout(smoothDown.bind(this, element), 10);
        } else {
          element.scrollTop = total;
        }
      }

      // 参数element为滚动区域

      function smoothUp(element) {
        if (distance > total) {
          distance -= step;

          element.scrollTop = distance;

          setTimeout(smoothUp.bind(this, element), 10);
        } else {
          element.scrollTop = total;
        }
      }

      document.querySelectorAll(".scroll-item").forEach((item, index1) => {
        if (index === index1) {
          item.scrollIntoView({
            block: "start",

            behavior: "smooth",
          });
        }
      });
    },
    merchant_commentFun() {

      let that = this;
      merchant_comment({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          this.commentInfo = res.data.info;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    jubao() {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return;
      }

      let that = this;
      report_shop({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          that.dialogVisible4 = false;
          that.$alert(res.msg, {
            callback: () => {
              that.getdata();
            },
          });
        })
        .catch((err) => {
          console.log(err);
          that.dialogVisible4 = false;
          that.$alert(err.msg, {
            callback: () => {
              that.getdata();
            },
          });
        });
    },
    Sure_meet() {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return;
      }

      let that = this;
      take_over({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          that.$alert(res.msg, {
            callback: () => {
              that.dialogVisible3 = false;
              that.getdata();
            },
          });
        })
        .catch((err) => {
          console.log(err);
          that.$alert(err.msg, {
            callback: () => {
              that.dialogVisible3 = false;
              that.getdata();
            },
          });
        });
    },
    copyFn(val) {
      console.log(val);
      // createElement() 方法通过指定名称创建一个元素
      var copyInput = document.createElement("input");
      //val是要复制的内容
      copyInput.setAttribute("value", val);
      document.body.appendChild(copyInput);
      copyInput.select();
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyInput);
          this.$message.success("已复制链接,快去分享吧");
          this.dialogVisible2 = false;
        }
      } catch {
        this.$message.error("复制失败，请检查浏览器兼容");
      }
    },
    // 取消收藏
    delecollectClick() {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return;
      }
      
      let that = this;
      that.dialogVisible = false;
      del_collect({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          this.$alert(res.msg, {
            callback: () => {
              that.getdata();
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.$alert(err.msg, {
            callback: () => {
              that.getdata();
            },
          });
        });
    },
    // 收藏
    collectClick() {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return;
      }

      let that = this;
      add_collect({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          this.$alert(res.msg, {
            callback: () => {
              that.getdata();
            },
          });
        })
        .catch((err) => {
          this.$alert(err.msg, {
            callback: () => {
              that.getdata();
            },
          });
        });
    },
    // 跳转详情
    detailsCut(item) {
      this.$router.push({
        path: "/transferdetails",
        query: { navid: 10, s_type: item.s_type, id: item.id },
      });
      // let routeData = this.$router.resolve({
      //   path: "/transferdetails",
      //   query: { navid: 10, s_type: item.s_type, id: item.id },
      // });
      // window.open(routeData.href, "_blank");
    },
    WinportClick(id) {
      this.type = id;
    },
    getdata() {
      var that = this;
      if (that.s_type == 1) {
        lease_val({
          uid: that.uid,
          id: that.id,
          pid: that.pid,
        })
          .then((res) => {
            console.log(res);
            let arr = [];
            if (res.data.val.cover) {
              arr.push(res.data.val.cover);
            }
            if (res.data.val.more_img && res.data.val.more_img.length) {
              res.data.val.more_img.forEach((item) => {
                arr.push(item);
              });
            }
            that.bannerImg = arr;
            res.data.val.introduce = res.data.val.introduce
              .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
              .replace(/<section/g, "<div")
              .replace(/\/section>/g, "div>");
            that.info = res.data.val;
            that.is_take_over = res.data.is_take_over;
            that.rich_val = res.data.rich_val;
            that.store_list = res.data.store_list.data
            that.getrecommend();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        ivn_sale_val({
          uid: that.uid,
          id: that.id,
          pid: that.pid,
        })
          .then((res) => {
            let arr = [];
            if (res.data.val.cover) {
              arr.push(res.data.val.cover);
            }
            if (res.data.val.more_img && res.data.val.more_img.length) {
              res.data.val.more_img.forEach((item) => {
                arr.push(item);
              });
            }
            that.bannerImg = arr;
            res.data.val.introduce = res.data.val.introduce
              .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
              .replace(/<section/g, "<div")
              .replace(/\/section>/g, "div>");
            that.info = res.data.val;
            that.is_take_over = res.data.is_take_over;
            that.rich_val = res.data.rich_val;
            that.store_list = res.data.store_list.data
            that.getrecommend();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getrecommend() {
      let that = this;
      similar_shop({
        city: that.city,
        lat: that.lat,
        lng: that.lng,
        fit_industry: that.info.fit_industry_id,
      })
        .then((res) => {
          // console.log('类似',res);
          this.recommendList = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
#pano_holder {
  min-width: 37.5rem;
  min-height: 37.5rem;
}

.distrbute_btns::-webkit-scrollbar {
  display: none;
}
.distrbute_btns {
  overflow-x: scroll;
  width: 100%;
  height: auto;
  // height: 100%;
  &_li {
    width: 7.0625rem;
    height: 4.625rem;
    margin-right: 0.625rem;
    position: relative;
    img {
      width: 7.0625rem;
      height: 4.625rem;
    }
  }

  &_li::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 7.0625rem;
    height: 4.625rem;
  }
  .active {
    position: relative;
  }
  .active::after {
    content: "";
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 7.0625rem;
    height: 4.625rem;
  }
}
.zs-adv {
  #adv-pad-scroll {
    position: relative;
    width: 100%;
    overflow: hidden;
    .adv-pad {
      width: max-content;
      display: flex;
      .adv-pad-item {
        position: relative;
        margin-right: 0.625rem;
        width: 7.0625rem;
        height: 4.625rem;
        cursor: pointer;
        animation: all 1.5s;
        img {
          width: 7.0625rem;
          height: 4.625rem;
          border-radius: 0.25rem;
        }
      }
      .adv-pad-item:hover {
        background-color: rgba(0, 0, 0, 0);
      }
      .adv-pad-item::after {
        content: "";
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        width: 7.0625rem;
        height: 4.625rem;
      }
      .active {
        position: relative;
      }
      .active::after {
        content: "";
        background-color: rgba(0, 0, 0, 0);
        position: absolute;
        top: 0;
        left: 0;
        width: 7.0625rem;
        height: 4.625rem;
      }
    }
  }
}

::v-deep .el-carousel__indicator--horizontal{
  padding: 0.75rem 0.25rem !important;
  .el-carousel__button{
    width: 1.875rem !important;
  }
}
</style>
